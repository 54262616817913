// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Errors$Weblab from "../service/Errors.bs.js";
import * as Belt_HashSetString from "rescript/lib/es6/belt_HashSetString.js";
import * as NotebookFormat$Weblab from "../service/NotebookFormat.bs.js";

function filesReducer(state, action) {
  switch (action.TAG | 0) {
    case /* AddFile */0 :
        var file_ = action._1;
        var name = action._0;
        Belt_HashSetString.add(state.files, name);
        switch (file_.TAG | 0) {
          case /* Notebook */0 :
          case /* PythonNotebook */1 :
              window.localStorage.setItem(name, file_._0);
              break;
          case /* PlainText */2 :
          case /* JSON */3 :
              break;
          
        }
        return {
                files: state.files
              };
    case /* Setup */1 :
        var file = action._1;
        var name$1 = action._0;
        for(var i = 0 ,i_finish = window.localStorage.length; i < i_finish; ++i){
          var key = window.localStorage.key(i);
          if (key.endsWith(".ijsnb") || key.endsWith(".ipynb") || key.endsWith(".json") || key.endsWith(".txt")) {
            Belt_HashSetString.add(state.files, key);
          }
          
        }
        Belt_HashSetString.add(state.files, name$1);
        switch (file.TAG | 0) {
          case /* Notebook */0 :
          case /* PythonNotebook */1 :
              window.localStorage.setItem(name$1, file._0);
              break;
          case /* PlainText */2 :
          case /* JSON */3 :
              break;
          
        }
        return {
                files: state.files
              };
    case /* DeleteFile */2 :
        var name$2 = action._0;
        Belt_HashSetString.remove(state.files, name$2);
        window.localStorage.removeItem(name$2);
        return {
                files: state.files
              };
    case /* ChangeName */3 :
        var newName = action._1;
        var oldName = action._0;
        if (Belt_HashSetString.has(state.files, oldName) && !Belt_HashSetString.has(state.files, newName)) {
          Belt_Option.forEach(Caml_option.nullable_to_opt(window.localStorage.getItem(oldName)), (function (item) {
                  Belt_HashSetString.remove(state.files, oldName);
                  Belt_HashSetString.add(state.files, newName);
                  window.localStorage.setItem(newName, item);
                  window.localStorage.removeItem(oldName);
                  
                }));
        }
        return {
                files: state.files
              };
    
  }
}

function getFileType(file) {
  var fileName = file.name;
  if (fileName.endsWith(".ijsnb")) {
    return file.text().then(function (x) {
                return Promise.resolve({
                            TAG: /* Notebook */0,
                            _0: x
                          });
              });
  } else if (fileName.endsWith(".ipynb")) {
    return file.text().then(function (x) {
                return Promise.resolve({
                            TAG: /* PythonNotebook */1,
                            _0: x
                          });
              });
  } else if (fileName.endsWith(".json")) {
    return Promise.resolve({
                TAG: /* JSON */3,
                _0: URL.createObjectURL(file)
              });
  } else if (fileName.endsWith(".txt")) {
    return file.text().then(function (x) {
                return Promise.resolve({
                            TAG: /* PlainText */2,
                            _0: x
                          });
              });
  } else {
    return Promise.reject(Js_exn.anyToExnInternal("Wrong file extension"));
  }
}

function get(files, name) {
  if (!Belt_HashSetString.has(files, name)) {
    return {
            TAG: /* Error */1,
            _0: {
              RE_EXN_ID: Errors$Weblab.FileNotFound
            }
          };
  }
  if (name.endsWith(".ijsnb")) {
    var item = window.localStorage.getItem(name);
    if (item == null) {
      return {
              TAG: /* Error */1,
              _0: {
                RE_EXN_ID: Errors$Weblab.FileNotFound
              }
            };
    } else {
      return {
              TAG: /* Ok */0,
              _0: {
                TAG: /* Notebook */0,
                _0: item
              }
            };
    }
  }
  if (name.endsWith(".ipynb")) {
    var item$1 = window.localStorage.getItem(name);
    if (item$1 == null) {
      return {
              TAG: /* Error */1,
              _0: {
                RE_EXN_ID: Errors$Weblab.FileNotFound
              }
            };
    } else {
      return {
              TAG: /* Ok */0,
              _0: {
                TAG: /* PythonNotebook */1,
                _0: item$1
              }
            };
    }
  }
  if (name.endsWith(".json")) {
    var item$2 = window.localStorage.getItem(name);
    if (item$2 == null) {
      return {
              TAG: /* Error */1,
              _0: {
                RE_EXN_ID: Errors$Weblab.FileNotFound
              }
            };
    } else {
      return {
              TAG: /* Ok */0,
              _0: {
                TAG: /* JSON */3,
                _0: item$2
              }
            };
    }
  }
  if (!name.endsWith(".txt")) {
    return {
            TAG: /* Error */1,
            _0: {
              RE_EXN_ID: Errors$Weblab.FileTypeNotSupported
            }
          };
  }
  var item$3 = window.localStorage.getItem(name);
  if (item$3 == null) {
    return {
            TAG: /* Error */1,
            _0: {
              RE_EXN_ID: Errors$Weblab.FileNotFound
            }
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: {
              TAG: /* PlainText */2,
              _0: item$3
            }
          };
  }
}

function fileOpen(name, files, notebookDispatch) {
  return Errors$Weblab.alertError(Belt_Result.map(get(files, name), (function (x) {
                    switch (x.TAG | 0) {
                      case /* Notebook */0 :
                          Errors$Weblab.alertError(Belt_Result.map(NotebookFormat$Weblab.parseJSONNotebook(x._0), (function (x) {
                                      return NotebookFormat$Weblab.notebookOpen(name, x, notebookDispatch);
                                    })));
                          return ;
                      case /* PythonNotebook */1 :
                          Errors$Weblab.alertError(Belt_Result.map(NotebookFormat$Weblab.parseJSONNotebook(x._0), (function (x) {
                                      return NotebookFormat$Weblab.notebookOpen(name, x, notebookDispatch);
                                    })));
                          return ;
                      case /* PlainText */2 :
                      case /* JSON */3 :
                          return ;
                      
                    }
                  })));
}

export {
  filesReducer ,
  getFileType ,
  get ,
  fileOpen ,
  
}
/* No side effect */
