// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Errors$Weblab from "../../service/Errors.bs.js";

function $$catch(response) {
  return response.then(function (response) {
              var match = response.data;
              var match$1 = response.error;
              if (!(match == null) && (match$1 == null)) {
                return Promise.resolve(match);
              }
              if (match$1 == null) {
                throw Errors$Weblab.toExn({
                          RE_EXN_ID: Errors$Weblab.Message,
                          _1: "Invalid response from storage."
                        });
                return Promise.reject(undefined);
              }
              throw Errors$Weblab.toExn({
                        RE_EXN_ID: Errors$Weblab.Message,
                        _1: match$1.message
                      });
              return Promise.reject(undefined);
            });
}

function catchUnit(response) {
  return response.then(function (response) {
              var match = response.data;
              var match$1 = response.error;
              if (!(match == null) && (match$1 == null)) {
                return Promise.resolve(undefined);
              }
              if (match$1 == null) {
                throw Errors$Weblab.toExn({
                          RE_EXN_ID: Errors$Weblab.Message,
                          _1: "Invalid response from storage."
                        });
                return Promise.reject(undefined);
              }
              throw Errors$Weblab.toExn({
                        RE_EXN_ID: Errors$Weblab.Message,
                        _1: match$1.message
                      });
              return Promise.reject(undefined);
            });
}

export {
  $$catch ,
  catchUnit ,
  
}
/* No side effect */
