// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Errors$Weblab from "./Errors.bs.js";
import * as Belt_HashMapInt from "rescript/lib/es6/belt_HashMapInt.js";
import * as Caml_splice_call from "rescript/lib/es6/caml_splice_call.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as EveryResult$Weblab from "./EveryResult.bs.js";
import * as NotebookBase$Weblab from "../components/NotebookBase.bs.js";
import * as WeblabMarkdown$Weblab from "../bindings/WeblabMarkdown.bs.js";
import * as WeblabInterpreter$Weblab from "../bindings/WeblabInterpreter.bs.js";

function parseJSONNotebook(json) {
  try {
    return {
            TAG: /* Ok */0,
            _0: JSON.parse(json)
          };
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Js_exn.$$Error) {
      return {
              TAG: /* Error */1,
              _0: {
                RE_EXN_ID: Errors$Weblab.NoRegularJSONNotebook
              }
            };
    }
    throw exn;
  }
}

function convertOutputJSONtoRE(jSONoutput) {
  var dataOpt = Js_dict.get(jSONoutput.data, "text/plain");
  if (dataOpt !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: {
              TAG: /* TextPlain */0,
              _0: dataOpt
            }
          };
  }
  var dataOpt$1 = Js_dict.get(jSONoutput.data, "text/html");
  if (dataOpt$1 !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: {
              TAG: /* TextHTML */1,
              _0: dataOpt$1
            }
          };
  }
  var dataOpt$2 = Js_dict.get(jSONoutput.data, "image/png");
  if (dataOpt$2 !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: {
              TAG: /* ImagePNG */2,
              _0: dataOpt$2
            }
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: {
              RE_EXN_ID: Errors$Weblab.UnsupportedOutputType
            }
          };
  }
}

function convertCellTypeJSONtoRE(cell_type) {
  if (cell_type === "code") {
    return {
            TAG: /* Ok */0,
            _0: /* Code */0
          };
  } else if (cell_type === "markdown") {
    return {
            TAG: /* Ok */0,
            _0: /* Markdown */1
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: {
              RE_EXN_ID: Errors$Weblab.UnsupportedCellType
            }
          };
  }
}

function getDisplayInputFromCellType(cell_type) {
  if (cell_type) {
    return "none";
  } else {
    return "inline-block";
  }
}

function convertOutputREtoJSON(output) {
  switch (output.TAG | 0) {
    case /* TextPlain */0 :
        return {
                output_type: "display_data",
                metadata: {},
                data: Js_dict.fromArray([[
                        "text/plain",
                        output._0
                      ]])
              };
    case /* TextHTML */1 :
        return {
                output_type: "display_data",
                metadata: {},
                data: Js_dict.fromArray([[
                        "text/html",
                        output._0
                      ]])
              };
    case /* ImagePNG */2 :
        return {
                output_type: "display_data",
                metadata: {},
                data: Js_dict.fromArray([[
                        "image/png",
                        output._0
                      ]])
              };
    
  }
}

function convertCellTypeREtoJSON(cell_type) {
  if (cell_type) {
    return "markdown";
  } else {
    return "code";
  }
}

function convertCellStateToJSONCell(state) {
  var match = state.cell_type;
  if (match) {
    return {
            cell_type: state.cell_type ? "markdown" : "code",
            execution_count: undefined,
            metadata: {},
            source: [state.source.contents],
            outputs: process.env.GATSBY_MARKDOWN_OUTPUT === "true" ? Belt_Array.map(state.outputs.contents, convertOutputREtoJSON) : undefined
          };
  } else {
    return {
            cell_type: state.cell_type ? "markdown" : "code",
            execution_count: 0,
            metadata: {},
            source: [state.source.contents],
            outputs: Belt_Array.map(state.outputs.contents, convertOutputREtoJSON)
          };
  }
}

function convertNotebookJSONtoRE(jsonNotebook) {
  return Promise.all(Belt_Array.mapWithIndex(jsonNotebook.cells, (function (i, jsonCell) {
                      var outputs = Belt_Option.getWithDefault(Belt_Option.map(jsonCell.outputs, (function (outputs) {
                                  return Promise.resolve(EveryResult$Weblab.everyArrayResult(Belt_Array.map(outputs, convertOutputJSONtoRE)));
                                })), WeblabMarkdown$Weblab.parse(Caml_splice_call.spliceObjApply("", "concat", [jsonCell.source])).then(function (output) {
                                return Promise.resolve({
                                            TAG: /* Ok */0,
                                            _0: [{
                                                TAG: /* TextPlain */0,
                                                _0: output
                                              }]
                                          });
                              }));
                      return outputs.then(function (outputs) {
                                  var cell_type = convertCellTypeJSONtoRE(jsonCell.cell_type);
                                  return Promise.resolve(Belt_Result.map(EveryResult$Weblab.everyTuple2Result([
                                                      outputs,
                                                      cell_type
                                                    ]), (function (x) {
                                                    return NotebookBase$Weblab.defaultCell(x[1], Belt_Option.getWithDefault(JSON.stringify(jsonCell.metadata), ""), Caml_splice_call.spliceObjApply("", "concat", [jsonCell.source]), x[0], x[1] ? "none" : "inline-block", i, undefined);
                                                  })));
                                });
                    }))).then(function (x) {
              return Promise.resolve(EveryResult$Weblab.everyArrayResult(x));
            });
}

function notebookOpen(name, jsonNotebook, notebookDispatch) {
  Promise.all([
            convertNotebookJSONtoRE(jsonNotebook),
            WeblabInterpreter$Weblab.resetEnvs(undefined)
          ]).then(function (x) {
          return Promise.resolve(Errors$Weblab.alertError(Belt_Result.map(x[0], (function (arr) {
                                var cells = Belt_HashMapInt.fromArray(Belt_Array.mapWithIndex(arr, (function (i, e) {
                                            return [
                                                    i,
                                                    e
                                                  ];
                                          })));
                                var indices = Belt_List.fromArray(Belt_Array.mapWithIndex(arr, (function (i, param) {
                                            return i;
                                          })));
                                return Curry._1(notebookDispatch, {
                                            TAG: /* OpenNotebook */5,
                                            _0: {
                                              name: name,
                                              count: arr.length,
                                              indices: indices,
                                              cells: cells
                                            }
                                          });
                              }))));
        }).catch(function (x) {
        alert("Error: Failed to open notebook.");
        return Promise.reject(Js_exn.anyToExnInternal(x));
      });
  
}

function notebookCopytoString(indices, cells) {
  var cellsJSON = Belt_Array.map(Belt_Array.keepMap(Belt_Array.map(Belt_List.toArray(indices), (function (i) {
                  return Belt_HashMapInt.get(cells, i);
                })), (function (x) {
              return x;
            })), convertCellStateToJSONCell);
  var notebookJSON_metadata = Js_dict.fromArray([
        [
          "kernel_info",
          Js_dict.fromArray([[
                  "name",
                  "Weblab"
                ]])
        ],
        [
          "language_info",
          Js_dict.fromArray([[
                  "name",
                  "javascript"
                ]])
        ]
      ]);
  var notebookJSON = {
    nbformat: 4,
    nbformat_minor: 0,
    metadata: notebookJSON_metadata,
    cells: cellsJSON
  };
  return Belt_Option.getWithDefault(JSON.stringify(notebookJSON), "Error: Failed to parse notebook.");
}

function convertNotebookJSONtoRESync(jsonNotebook) {
  return EveryResult$Weblab.everyArrayResult(Belt_Array.mapWithIndex(jsonNotebook.cells, (function (i, jsonCell) {
                    var outputs = Belt_Option.getWithDefault(Belt_Option.map(jsonCell.outputs, (function (outputs) {
                                return EveryResult$Weblab.everyArrayResult(Belt_Array.map(outputs, convertOutputJSONtoRE));
                              })), {
                          TAG: /* Ok */0,
                          _0: [{
                              TAG: /* TextPlain */0,
                              _0: ""
                            }]
                        });
                    var cell_type = convertCellTypeJSONtoRE(jsonCell.cell_type);
                    return Belt_Result.map(EveryResult$Weblab.everyTuple2Result([
                                    outputs,
                                    cell_type
                                  ]), (function (x) {
                                  return NotebookBase$Weblab.defaultCell(x[1], Belt_Option.getWithDefault(JSON.stringify(jsonCell.metadata), ""), Caml_splice_call.spliceObjApply("", "concat", [jsonCell.source]), x[0], x[1] ? "none" : "inline-block", i, undefined);
                                }));
                  })));
}

export {
  parseJSONNotebook ,
  convertOutputJSONtoRE ,
  convertCellTypeJSONtoRE ,
  getDisplayInputFromCellType ,
  convertOutputREtoJSON ,
  convertCellTypeREtoJSON ,
  convertCellStateToJSONCell ,
  convertNotebookJSONtoRE ,
  notebookOpen ,
  notebookCopytoString ,
  convertNotebookJSONtoRESync ,
  
}
/* No side effect */
