// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Errors$Weblab from "../service/Errors.bs.js";
import * as Belt_HashMapInt from "rescript/lib/es6/belt_HashMapInt.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as FilesBase$Weblab from "../components/FilesBase.bs.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as NotebookFormat$Weblab from "../service/NotebookFormat.bs.js";
import * as SupabaseClient$Weblab from "../bindings/supabase/SupabaseClient.bs.js";
import * as WeblabMarkdown$Weblab from "../bindings/WeblabMarkdown.bs.js";
import * as SupabaseStorage$Weblab from "../bindings/supabase/SupabaseStorage.bs.js";
import * as SupabaseDatabase$Weblab from "../bindings/supabase/SupabaseDatabase.bs.js";

function reducer(state, action) {
  if (typeof action === "number") {
    if (action === /* ClearMyNotebooks */0) {
      return {
              myNotebooks: undefined,
              mostViewed: state.mostViewed,
              mostViewedCount: state.mostViewedCount,
              search: state.search
            };
    } else {
      return {
              myNotebooks: state.myNotebooks,
              mostViewed: state.mostViewed,
              mostViewedCount: state.mostViewedCount,
              search: undefined
            };
    }
  }
  switch (action.TAG | 0) {
    case /* Initialize */0 :
        return {
                myNotebooks: Caml_option.some(Belt_HashMapInt.fromArray(Belt_Array.mapWithIndex(action._0, (function (i, x) {
                                return [
                                        Belt_Option.getWithDefault(Caml_option.nullable_to_opt(x.id), i),
                                        x
                                      ];
                              })))),
                mostViewed: action._1,
                mostViewedCount: 1,
                search: undefined
              };
    case /* InitializeMyNotebooks */1 :
        return {
                myNotebooks: Caml_option.some(Belt_HashMapInt.fromArray(Belt_Array.mapWithIndex(action._0, (function (i, x) {
                                return [
                                        Belt_Option.getWithDefault(Caml_option.nullable_to_opt(x.id), i),
                                        x
                                      ];
                              })))),
                mostViewed: state.mostViewed,
                mostViewedCount: state.mostViewedCount,
                search: undefined
              };
    case /* InitializeMostViewed */2 :
        return {
                myNotebooks: state.myNotebooks,
                mostViewed: action._0,
                mostViewedCount: 1,
                search: undefined
              };
    case /* LoadMostViewed */3 :
        var match = state.mostViewed;
        var match$1 = state.mostViewedCount;
        if (match !== undefined && match$1 !== undefined) {
          return {
                  myNotebooks: state.myNotebooks,
                  mostViewed: Belt_Array.concat(match, action._0),
                  mostViewedCount: match$1 + 1 | 0,
                  search: state.search
                };
        } else {
          return state;
        }
    case /* Search */4 :
        return {
                myNotebooks: state.myNotebooks,
                mostViewed: state.mostViewed,
                mostViewedCount: state.mostViewedCount,
                search: action._0
              };
    case /* RemoveMyNotebook */5 :
        var myNotebooks = state.myNotebooks;
        if (myNotebooks === undefined) {
          return state;
        }
        var myNotebooks$1 = Caml_option.valFromOption(myNotebooks);
        Belt_HashMapInt.remove(myNotebooks$1, action._0);
        return {
                myNotebooks: Caml_option.some(myNotebooks$1),
                mostViewed: state.mostViewed,
                mostViewedCount: state.mostViewedCount,
                search: state.search
              };
    case /* UpdateMyNotebook */6 :
        var notebook = action._0;
        var match$2 = state.myNotebooks;
        var match$3 = notebook.id;
        if (match$2 === undefined) {
          return state;
        }
        if (match$3 == null) {
          return state;
        }
        var myNotebooks$2 = Caml_option.valFromOption(match$2);
        Belt_HashMapInt.set(myNotebooks$2, match$3, notebook);
        return {
                myNotebooks: Caml_option.some(myNotebooks$2),
                mostViewed: state.mostViewed,
                mostViewedCount: state.mostViewedCount,
                search: state.search
              };
    
  }
}

function asyncReducer(dispatch, action) {
  switch (action.TAG | 0) {
    case /* AsyncInitialize */0 :
        var session = action._0;
        var myNotebooksProm = session !== undefined ? SupabaseDatabase$Weblab.$$catch(SupabaseClient$Weblab.supabase.from("notebooks").select("*, views (count)", undefined).eq("owner_id", session.user.id)).then(function (data) {
                  return Promise.resolve(data);
                }).catch(function (error) {
                Errors$Weblab.alertError({
                      TAG: /* Error */1,
                      _0: Errors$Weblab.fromPromiseError(error)
                    });
                return Promise.resolve(undefined);
              }) : Promise.resolve(undefined);
        var mostViewedProm = SupabaseDatabase$Weblab.$$catch(SupabaseClient$Weblab.supabase.from("notebooks").select("*, views (count)", undefined).order("count", {
                        foreignTable: "views",
                        ascending: false
                      }).limit(12, undefined)).then(function (data) {
                return Promise.resolve(data);
              }).catch(function (error) {
              Errors$Weblab.alertError({
                    TAG: /* Error */1,
                    _0: Errors$Weblab.fromPromiseError(error)
                  });
              return Promise.resolve(undefined);
            });
        Promise.all([
                myNotebooksProm,
                mostViewedProm
              ]).then(function (args) {
              var myNotebooks = args[0];
              if (myNotebooks !== undefined) {
                var mostViewed = args[1];
                if (mostViewed !== undefined) {
                  Curry._1(dispatch, {
                        TAG: /* Initialize */0,
                        _0: myNotebooks,
                        _1: mostViewed
                      });
                  return Promise.resolve(undefined);
                } else {
                  return Promise.resolve(undefined);
                }
              }
              var mostViewed$1 = args[1];
              if (mostViewed$1 !== undefined) {
                Curry._1(dispatch, {
                      TAG: /* InitializeMostViewed */2,
                      _0: mostViewed$1
                    });
                return Promise.resolve(undefined);
              } else {
                return Promise.resolve(undefined);
              }
            });
        break;
    case /* AsyncInitializeMyNotebooks */1 :
        var session$1 = action._0;
        if (session$1 !== undefined) {
          SupabaseDatabase$Weblab.$$catch(SupabaseClient$Weblab.supabase.from("notebooks").select("*, views (count)", undefined).eq("owner_id", session$1.user.id)).then(function (myNotebooks) {
                  Curry._1(dispatch, {
                        TAG: /* InitializeMyNotebooks */1,
                        _0: myNotebooks
                      });
                  return Promise.resolve(undefined);
                }).catch(function (error) {
                Errors$Weblab.alertError({
                      TAG: /* Error */1,
                      _0: Errors$Weblab.fromPromiseError(error)
                    });
                return Promise.resolve(undefined);
              });
        } else {
          Curry._1(dispatch, /* ClearMyNotebooks */0);
          Promise.resolve(undefined);
        }
        break;
    case /* AsyncLoadMostViewed */2 :
        var count = action._0;
        SupabaseDatabase$Weblab.$$catch(SupabaseClient$Weblab.supabase.from("notebooks").select("*, views (count)", undefined).order("count", {
                        foreignTable: "views",
                        ascending: false
                      }).range(Math.imul(count, 12), Math.imul(count + 1 | 0, 12) - 1 | 0, undefined)).then(function (mostViewed) {
                Curry._1(dispatch, {
                      TAG: /* LoadMostViewed */3,
                      _0: mostViewed
                    });
                return Promise.resolve(undefined);
              }).catch(function (error) {
              Errors$Weblab.alertError({
                    TAG: /* Error */1,
                    _0: Errors$Weblab.fromPromiseError(error)
                  });
              return Promise.resolve(undefined);
            });
        break;
    case /* AsyncRemoveMyNotebook */3 :
        var id = action._2;
        SupabaseStorage$Weblab.catchUnit(SupabaseClient$Weblab.supabase.storage.from("notebooks").remove([action._0.user.id + "/" + action._1])).then(function (param) {
                  return SupabaseDatabase$Weblab.catchUnit(SupabaseClient$Weblab.supabase.from("notebooks").delete().match({
                                  id: Js_null_undefined.fromOption(id)
                                }));
                }).then(function (param) {
                Curry._1(dispatch, {
                      TAG: /* RemoveMyNotebook */5,
                      _0: id
                    });
                return Promise.resolve(undefined);
              }).catch(function (error) {
              Errors$Weblab.alertError({
                    TAG: /* Error */1,
                    _0: Errors$Weblab.fromPromiseError(error)
                  });
              return Promise.resolve(undefined);
            });
        break;
    case /* AsyncUpdateMyNotebook */4 :
        var notebook = action._1;
        SupabaseDatabase$Weblab.catchUnit(SupabaseClient$Weblab.supabase.from("notebooks").update({
                        id: notebook.id,
                        name: notebook.name,
                        owner_id: notebook.owner_id,
                        public: notebook.public,
                        views: Js_null_undefined.fromOption(undefined),
                        preview: notebook.preview,
                        created: notebook.created,
                        tags: notebook.tags,
                        url: notebook.url
                      }).match({
                      id: Js_null_undefined.fromOption(action._0)
                    })).then(function (param) {
                Curry._1(dispatch, {
                      TAG: /* UpdateMyNotebook */6,
                      _0: notebook
                    });
                return Promise.resolve(undefined);
              }).catch(function (error) {
              Errors$Weblab.alertError({
                    TAG: /* Error */1,
                    _0: Errors$Weblab.fromPromiseError(error)
                  });
              return Promise.resolve(undefined);
            });
        break;
    case /* AsyncSearch */5 :
        SupabaseDatabase$Weblab.$$catch(SupabaseClient$Weblab.supabase.from("notebooks").select("*, views (count)", undefined).textSearch("tags", Belt_Array.reduce(Belt_Array.map(action._0.split(" "), (function (x) {
                                  return "'" + x + "' ";
                                })), "", (function (prim0, prim1) {
                              return prim0.concat(prim1);
                            })).replace(/(\s)'/g, " | "))).then(function (arr) {
                Curry._1(dispatch, {
                      TAG: /* Search */4,
                      _0: arr
                    });
                return Promise.resolve(undefined);
              }).catch(function (error) {
              Errors$Weblab.alertError({
                    TAG: /* Error */1,
                    _0: Errors$Weblab.fromPromiseError(error)
                  });
              return Promise.resolve(undefined);
            });
        break;
    
  }
  
}

function removeNotebookFromNotebookHub(session, name, notebook) {
  return SupabaseStorage$Weblab.catchUnit(SupabaseClient$Weblab.supabase.storage.from("notebooks").remove([session.user.id + "/" + name])).then(function (param) {
                return SupabaseDatabase$Weblab.catchUnit(SupabaseClient$Weblab.supabase.from("notebooks").delete().match({
                                id: notebook.id
                              }));
              }).catch(function (error) {
              Errors$Weblab.alertError({
                    TAG: /* Error */1,
                    _0: Errors$Weblab.fromPromiseError(error)
                  });
              return Promise.resolve(undefined);
            });
}

var NoMarkdownInNotebook = /* @__PURE__ */Caml_exceptions.create("HubBase-Weblab.NoMarkdownInNotebook");

function createPreview(str) {
  var cell = Belt_Result.flatMap(NotebookFormat$Weblab.parseJSONNotebook(str), (function (notebook) {
          var cell = Belt_Array.getBy(notebook.cells, (function (cell) {
                  return cell.cell_type === "markdown";
                }));
          if (cell !== undefined) {
            return {
                    TAG: /* Ok */0,
                    _0: cell
                  };
          } else {
            return {
                    TAG: /* Error */1,
                    _0: {
                      RE_EXN_ID: NoMarkdownInNotebook
                    }
                  };
          }
        }));
  if (cell.TAG === /* Ok */0) {
    return WeblabMarkdown$Weblab.parse(Caml_array.get(cell._0.source, 0));
  }
  throw Errors$Weblab.toExn(cell._0);
  return Promise.reject(undefined);
}

var FileExistsInStorage = /* @__PURE__ */Caml_exceptions.create("HubBase-Weblab.FileExistsInStorage");

function shareFileOnNotebookHub(session, files, name) {
  var file = Belt_Result.map(FilesBase$Weblab.get(files, name), (function (file) {
          return file._0;
        }));
  if (file.TAG === /* Ok */0) {
    var file$1 = file._0;
    var path = session.user.id + "/" + name;
    var previewProm = createPreview(file$1).then(function (str) {
            return Promise.resolve(str);
          }).catch(function (err) {
          var x = Errors$Weblab.fromPromiseError(err);
          if (x.RE_EXN_ID === NoMarkdownInNotebook) {
            return Promise.resolve(undefined);
          } else {
            return Promise.reject(Errors$Weblab.toExn(x));
          }
        });
    var urlProm = SupabaseClient$Weblab.supabase.storage.from("notebooks").upload(path, file$1).then(function (response) {
              var match = response.error;
              if (match == null) {
                return Promise.resolve(undefined);
              }
              if (confirm("The notebook already exists. Do you want to override it?")) {
                throw Errors$Weblab.toExn({
                          RE_EXN_ID: FileExistsInStorage
                        });
                return Promise.reject(undefined);
              }
              throw Errors$Weblab.toExn({
                        RE_EXN_ID: Errors$Weblab.Message,
                        _1: "The notebook wasn't uploaded to NotebookHub."
                      });
              return Promise.reject(undefined);
            }).then(function (param) {
            return Promise.resolve(SupabaseClient$Weblab.supabase.storage.from("notebooks").getPublicUrl(path));
          }).then(function (response) {
          var match = response.publicURL;
          if (!(match == null)) {
            return Promise.resolve(match);
          }
          throw Errors$Weblab.toExn({
                    RE_EXN_ID: Errors$Weblab.Message,
                    _1: "Failed to get Public url."
                  });
          return Promise.reject(undefined);
        });
    var insertProm = Promise.all([
                  urlProm,
                  previewProm
                ]).then(function (output) {
                return SupabaseDatabase$Weblab.catchUnit(SupabaseClient$Weblab.supabase.from("notebooks").insert({
                                id: Js_null_undefined.fromOption(undefined),
                                name: Js_null_undefined.fromOption(name),
                                owner_id: Js_null_undefined.fromOption(session.user.id),
                                public: Js_null_undefined.fromOption(true),
                                views: Js_null_undefined.fromOption(undefined),
                                preview: Js_null_undefined.fromOption(output[1]),
                                created: Js_null_undefined.fromOption(new Date().toISOString().slice(0, 19)),
                                tags: Js_null_undefined.fromOption("data science"),
                                url: Js_null_undefined.fromOption(output[0])
                              }));
              }).catch(function (err) {
              var x = Errors$Weblab.fromPromiseError(err);
              if (x.RE_EXN_ID === FileExistsInStorage) {
                return SupabaseStorage$Weblab.catchUnit(SupabaseClient$Weblab.supabase.storage.from("notebooks").update(path, file$1));
              } else {
                return Promise.reject(Errors$Weblab.toExn(x));
              }
            }).then(function (param) {
            return Promise.resolve(SupabaseClient$Weblab.supabase.storage.from("notebooks").getPublicUrl(path));
          }).then(function (response) {
          var match = response.publicURL;
          if (!(match == null)) {
            return Promise.resolve(match);
          }
          throw Errors$Weblab.toExn({
                    RE_EXN_ID: Errors$Weblab.Message,
                    _1: "Failed to get Public url."
                  });
          return Promise.reject(undefined);
        });
    return Promise.all([
                  insertProm,
                  previewProm
                ]).then(function (output) {
                return SupabaseDatabase$Weblab.catchUnit(SupabaseClient$Weblab.supabase.from("notebooks").update({
                                  id: Js_null_undefined.fromOption(undefined),
                                  name: Js_null_undefined.fromOption(name),
                                  owner_id: Js_null_undefined.fromOption(session.user.id),
                                  public: Js_null_undefined.fromOption(true),
                                  views: Js_null_undefined.fromOption(undefined),
                                  preview: Js_null_undefined.fromOption(output[1]),
                                  created: Js_null_undefined.fromOption(new Date().toISOString().slice(0, 19)),
                                  tags: Js_null_undefined.fromOption("data science"),
                                  url: Js_null_undefined.fromOption(output[0])
                                }).match({
                                name: Js_null_undefined.fromOption(name),
                                owner_id: Js_null_undefined.fromOption(session.user.id)
                              }));
              });
  }
  throw Errors$Weblab.toExn(file._0);
  return Promise.reject(undefined);
}

var loadSize = 12;

export {
  loadSize ,
  reducer ,
  asyncReducer ,
  removeNotebookFromNotebookHub ,
  NoMarkdownInNotebook ,
  createPreview ,
  FileExistsInStorage ,
  shareFileOnNotebookHub ,
  
}
/* SupabaseClient-Weblab Not a pure module */
