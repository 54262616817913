// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_HashMapInt from "rescript/lib/es6/belt_HashMapInt.js";
import * as WeblabMarkdown$Weblab from "../bindings/WeblabMarkdown.bs.js";
import * as WeblabInterpreter$Weblab from "../bindings/WeblabInterpreter.bs.js";

function defaultCell(cell_typeOpt, metadataOpt, sourceOpt, outputsOpt, display_inputOpt, indexOpt, param) {
  var cell_type = cell_typeOpt !== undefined ? cell_typeOpt : /* Code */0;
  var metadata = metadataOpt !== undefined ? metadataOpt : "";
  var source = sourceOpt !== undefined ? sourceOpt : "";
  var outputs = outputsOpt !== undefined ? outputsOpt : [{
        TAG: /* TextPlain */0,
        _0: ""
      }];
  var display_input = display_inputOpt !== undefined ? display_inputOpt : "inline-block";
  var index = indexOpt !== undefined ? indexOpt : 0;
  return {
          cell_type: cell_type,
          metadata: metadata,
          source: {
            contents: source
          },
          outputs: {
            contents: outputs
          },
          display_input: {
            contents: display_input
          },
          index: index
        };
}

function notebookReducer(state, action) {
  if (typeof action === "number") {
    Belt_HashMapInt.forEach(state.cells, (function (_key, cellState) {
            var match = cellState.cell_type;
            if (match) {
              return ;
            } else {
              cellState.outputs.contents = [{
                  TAG: /* TextPlain */0,
                  _0: ""
                }];
              return ;
            }
          }));
    return {
            name: state.name,
            count: state.count,
            indices: state.indices,
            cells: state.cells
          };
  }
  switch (action.TAG | 0) {
    case /* AddCodeCell */0 :
        var list = Belt_Option.map(Belt_Option.flatMap(Belt_Option.flatMap(action._0, (function (i) {
                        return Belt_List.getBy(Belt_List.mapWithIndex(state.indices, (function (index, x) {
                                          if (x === i) {
                                            return index;
                                          }
                                          
                                        })), Belt_Option.isSome);
                      })), (function (x) {
                    return Belt_Option.flatMap(x, (function (y) {
                                  return Belt_List.splitAt(state.indices, y + 1 | 0);
                                }));
                  })), (function (param) {
                return Belt_List.concat(param[0], Belt_List.concat({
                                hd: state.count,
                                tl: /* [] */0
                              }, param[1]));
              }));
        if (list !== undefined) {
          return {
                  name: state.name,
                  count: state.count + 1 | 0,
                  indices: list,
                  cells: (Belt_HashMapInt.set(state.cells, state.count, defaultCell(/* Code */0, undefined, undefined, undefined, undefined, state.count, undefined)), state.cells)
                };
        } else {
          return {
                  name: state.name,
                  count: state.count + 1 | 0,
                  indices: Belt_List.concat(state.indices, {
                        hd: state.count,
                        tl: /* [] */0
                      }),
                  cells: (Belt_HashMapInt.set(state.cells, state.count, defaultCell(/* Code */0, undefined, undefined, undefined, undefined, state.count, undefined)), state.cells)
                };
        }
    case /* AddMarkdownCell */1 :
        var list$1 = Belt_Option.map(Belt_Option.flatMap(Belt_Option.flatMap(action._0, (function (i) {
                        return Belt_List.getBy(Belt_List.mapWithIndex(state.indices, (function (index, x) {
                                          if (x === i) {
                                            return index;
                                          }
                                          
                                        })), Belt_Option.isSome);
                      })), (function (x) {
                    return Belt_Option.flatMap(x, (function (y) {
                                  return Belt_List.splitAt(state.indices, y + 1 | 0);
                                }));
                  })), (function (param) {
                return Belt_List.concat(param[0], Belt_List.concat({
                                hd: state.count,
                                tl: /* [] */0
                              }, param[1]));
              }));
        if (list$1 !== undefined) {
          return {
                  name: state.name,
                  count: state.count + 1 | 0,
                  indices: list$1,
                  cells: (Belt_HashMapInt.set(state.cells, state.count, defaultCell(/* Markdown */1, undefined, undefined, undefined, undefined, state.count, undefined)), console.log(defaultCell(/* Markdown */1, undefined, undefined, undefined, undefined, state.count, undefined)), state.cells)
                };
        } else {
          return {
                  name: state.name,
                  count: state.count + 1 | 0,
                  indices: Belt_List.concat(state.indices, {
                        hd: state.count,
                        tl: /* [] */0
                      }),
                  cells: (Belt_HashMapInt.set(state.cells, state.count, defaultCell(/* Markdown */1, undefined, undefined, undefined, undefined, state.count, undefined)), state.cells)
                };
        }
    case /* DeleteCell */2 :
        var list$2 = Belt_Option.flatMap(action._0, (function (i) {
                return Belt_Option.flatMap(Belt_Option.flatMap(Belt_List.getBy(Belt_List.mapWithIndex(state.indices, (function (index, x) {
                                          if (x === i) {
                                            return index;
                                          }
                                          
                                        })), Belt_Option.isSome), (function (x) {
                                  return Belt_Option.flatMap(x, (function (y) {
                                                return Belt_List.splitAt(state.indices, y);
                                              }));
                                })), (function (param) {
                              var head = param[0];
                              return Belt_Option.map(Belt_List.tail(param[1]), (function (z) {
                                            Belt_HashMapInt.remove(state.cells, i);
                                            return Belt_List.concat(head, z);
                                          }));
                            }));
              }));
        if (list$2 !== undefined) {
          return {
                  name: state.name,
                  count: state.count,
                  indices: list$2,
                  cells: state.cells
                };
        } else {
          return state;
        }
    case /* MoveCellUp */3 :
        var list$3 = Belt_Option.map(Belt_Option.flatMap(Belt_Option.flatMap(action._0, (function (i) {
                        return Belt_List.getBy(Belt_List.mapWithIndex(state.indices, (function (index, x) {
                                          if (x === i) {
                                            return index;
                                          }
                                          
                                        })), Belt_Option.isSome);
                      })), (function (x) {
                    return Belt_Option.flatMap(x, (function (y) {
                                  return Belt_List.splitAt(state.indices, y - 1 | 0);
                                }));
                  })), (function (param) {
                var tail = param[1];
                var head = param[0];
                if (!tail) {
                  return head;
                }
                var match = tail.tl;
                var cell = tail.hd;
                if (!match) {
                  return Belt_List.concat(head, {
                              hd: cell,
                              tl: /* [] */0
                            });
                }
                var newtail = match.tl;
                var next = match.hd;
                if (newtail) {
                  return Belt_List.concat(head, Belt_List.concat({
                                  hd: next,
                                  tl: {
                                    hd: cell,
                                    tl: /* [] */0
                                  }
                                }, newtail));
                } else {
                  return Belt_List.concat(head, {
                              hd: next,
                              tl: {
                                hd: cell,
                                tl: /* [] */0
                              }
                            });
                }
              }));
        if (list$3 !== undefined) {
          return {
                  name: state.name,
                  count: state.count,
                  indices: list$3,
                  cells: state.cells
                };
        } else {
          return state;
        }
    case /* MoveCellDown */4 :
        var list$4 = Belt_Option.map(Belt_Option.flatMap(Belt_Option.flatMap(action._0, (function (i) {
                        return Belt_List.getBy(Belt_List.mapWithIndex(state.indices, (function (index, x) {
                                          if (x === i) {
                                            return index;
                                          }
                                          
                                        })), Belt_Option.isSome);
                      })), (function (x) {
                    return Belt_Option.flatMap(x, (function (y) {
                                  return Belt_List.splitAt(state.indices, y);
                                }));
                  })), (function (param) {
                var tail = param[1];
                var head = param[0];
                if (!tail) {
                  return head;
                }
                var match = tail.tl;
                var cell = tail.hd;
                if (!match) {
                  return Belt_List.concat(head, {
                              hd: cell,
                              tl: /* [] */0
                            });
                }
                var newtail = match.tl;
                var next = match.hd;
                if (newtail) {
                  return Belt_List.concat(head, Belt_List.concat({
                                  hd: next,
                                  tl: {
                                    hd: cell,
                                    tl: /* [] */0
                                  }
                                }, newtail));
                } else {
                  return Belt_List.concat(head, {
                              hd: next,
                              tl: {
                                hd: cell,
                                tl: /* [] */0
                              }
                            });
                }
              }));
        if (list$4 !== undefined) {
          return {
                  name: state.name,
                  count: state.count,
                  indices: list$4,
                  cells: state.cells
                };
        } else {
          return state;
        }
    case /* OpenNotebook */5 :
        return action._0;
    case /* DisplayCellOutput */6 :
        var display_input = action._2;
        var outputs = action._1;
        var cellStateOpt = Belt_HashMapInt.get(state.cells, action._0);
        Belt_Option.getWithDefault(Belt_Option.map(cellStateOpt, (function (cellState) {
                    cellState.outputs.contents = outputs;
                    cellState.display_input.contents = display_input;
                    
                  })), undefined);
        return {
                name: state.name,
                count: state.count,
                indices: state.indices,
                cells: state.cells
              };
    case /* ChangeNotebookName */7 :
        return {
                name: action._0,
                count: state.count,
                indices: state.indices,
                cells: state.cells
              };
    case /* ClearCodeOutput */8 :
        Belt_Option.flatMap(action._0, (function (k) {
                return Belt_Option.map(Belt_Option.flatMap(Belt_List.get(state.indices, k), (function (j) {
                                  return Belt_HashMapInt.get(state.cells, j);
                                })), (function (cellState) {
                              var match = cellState.cell_type;
                              if (match) {
                                return ;
                              } else {
                                cellState.outputs.contents = [{
                                    TAG: /* TextPlain */0,
                                    _0: ""
                                  }];
                                return ;
                              }
                            }));
              }));
        return {
                name: state.name,
                count: state.count,
                indices: state.indices,
                cells: state.cells
              };
    
  }
}

function evalCell(cellState, notebookDispatch) {
  var match = cellState.cell_type;
  if (match) {
    return WeblabMarkdown$Weblab.parse(cellState.source.contents).then(function (output) {
                  return Promise.resolve(Curry._1(notebookDispatch, {
                                  TAG: /* DisplayCellOutput */6,
                                  _0: cellState.index,
                                  _1: [{
                                      TAG: /* TextPlain */0,
                                      _0: output
                                    }],
                                  _2: "none"
                                }));
                }).catch(function (param) {
                return Promise.resolve(Curry._1(notebookDispatch, {
                                TAG: /* DisplayCellOutput */6,
                                _0: cellState.index,
                                _1: [{
                                    TAG: /* TextPlain */0,
                                    _0: "Could not parse markdown input"
                                  }],
                                _2: "inline-block"
                              }));
              });
  } else {
    return WeblabInterpreter$Weblab.evalCell(cellState.source.contents).then(function (output) {
                  return Promise.resolve(Curry._1(notebookDispatch, {
                                  TAG: /* DisplayCellOutput */6,
                                  _0: cellState.index,
                                  _1: [output],
                                  _2: "inline-block"
                                }));
                }).catch(function (param) {
                return Promise.resolve(Curry._1(notebookDispatch, {
                                TAG: /* DisplayCellOutput */6,
                                _0: cellState.index,
                                _1: [{
                                    TAG: /* TextPlain */0,
                                    _0: "Could not evaluate code input"
                                  }],
                                _2: "inline-block"
                              }));
              });
  }
}

export {
  defaultCell ,
  notebookReducer ,
  evalCell ,
  
}
/* No side effect */
