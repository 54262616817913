// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_types from "rescript/lib/es6/js_types.js";

function resetEnvs(param) {
  return import(("@weblab-notebook/weblab-interpreter"+"")).then(function (module_) {
              return Promise.resolve(Curry._1(module_.reset_envs, undefined));
            });
}

function evalCell(input) {
  return import(("@weblab-notebook/weblab-interpreter"+"")).then(function (module_) {
                return Curry._1(module_.eval_cell, input);
              }).then(function (output) {
              var text = Js_types.classify(output);
              if (typeof text === "number") {
                return Promise.reject(Js_exn.anyToExnInternal("Wrong type of interpreter output."));
              }
              switch (text.TAG | 0) {
                case /* JSString */1 :
                    return Promise.resolve({
                                TAG: /* TextPlain */0,
                                _0: text._0
                              });
                case /* JSObject */3 :
                    return Promise.resolve({
                                TAG: /* TextHTML */1,
                                _0: output.outerHTML
                              });
                default:
                  return Promise.reject(Js_exn.anyToExnInternal("Wrong type of interpreter output."));
              }
            });
}

function listProperties(name) {
  return import(("@weblab-notebook/weblab-interpreter"+"")).then(function (module_) {
              return Promise.resolve(Curry._1(module_.list_properties, name));
            });
}

function getType(name) {
  return import(("@weblab-notebook/weblab-interpreter"+"")).then(function (module_) {
              return Promise.resolve(Curry._1(module_.get_type, name));
            });
}

export {
  resetEnvs ,
  evalCell ,
  listProperties ,
  getType ,
  
}
/* No side effect */
