// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";

function parse(input) {
  return import(("@weblab-notebook/weblab-markdown"+"")).then(function (module_) {
                return Promise.resolve(Curry._1(module_.markdown, input));
              }).catch(function (param) {
              return Promise.resolve("Failed to import module weblab-markdown");
            });
}

export {
  parse ,
  
}
/* No side effect */
