// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Errors$Weblab from "./Errors.bs.js";

function everyArrayResult(a) {
  return Belt_Array.reduce(a, {
              TAG: /* Ok */0,
              _0: []
            }, (function (array, b) {
                if (array.TAG === /* Ok */0) {
                  if (b.TAG === /* Ok */0) {
                    return {
                            TAG: /* Ok */0,
                            _0: Belt_Array.concat(array._0, [b._0])
                          };
                  } else {
                    return {
                            TAG: /* Error */1,
                            _0: b._0
                          };
                  }
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: array._0
                        };
                }
              }));
}

function everyTuple2Result(x) {
  var a = x[0];
  if (a.TAG === /* Ok */0) {
    var b = x[1];
    if (b.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: [
                a._0,
                b._0
              ]
            };
    } else {
      return {
              TAG: /* Error */1,
              _0: b._0
            };
    }
  }
  var err2 = x[1];
  var err = a._0;
  if (err2.TAG === /* Ok */0) {
    return {
            TAG: /* Error */1,
            _0: err
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: {
              RE_EXN_ID: Errors$Weblab.MultipleErrors,
              _1: {
                hd: err,
                tl: {
                  hd: err2._0,
                  tl: /* [] */0
                }
              }
            }
          };
  }
}

export {
  everyArrayResult ,
  everyTuple2Result ,
  
}
/* No side effect */
